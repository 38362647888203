{
  "name": "gps",
  "version": "1.78.1",
  "engines": {
    "npm": "^8.0.0"
  },
  "scripts": {
    "start": "ng serve --disable-host-check --open",
    "serve:local": "ng serve --disable-host-check",
    "serve:dev": "ng serve --configuration=dev --disable-host-check",
    "serve:preprod": "ng serve --configuration=preprod --disable-host-check",
    "serve:prod": "ng serve --configuration=prod --disable-host-check",
    "build": "npm run build:prod && npm run build:preprod && npm run build:dev",
    "build:prod": "ng build --configuration=prod && gzipper compress --verbose ./dist/prod",
    "build:preprod": "ng build --configuration=preprod && gzipper compress --verbose ./dist/preprod",
    "build:preview": "ng build --configuration=preview && gzipper compress --verbose ./dist/preview",
    "build:dev": "ng build --configuration=dev && gzipper compress --verbose ./dist/dev",
    "test": "ng test --browsers=Chrome",
    "test:coverage-watch": "ng test --browsers=Chrome --code-coverage --watch",
    "test:coverage": "ng test --browsers=Chrome --code-coverage",
    "test:ci": "ng test --code-coverage --no-watch --no-progress --source-map=false --karma-config=ci.karma.conf.js",
    "test:watch": "ng test --browsers=Chrome --watch",
    "lint": "ng lint && npm run lint:styles",
    "lint:styles": "stylelint \"src/**/*.scss\"",
    "clean": "rmdir /s /q node_modules && npm cache clean --force",
    "prepare": "is-ci || husky install",
    "format": "prettier --write .",
    "analyze": "ng build --stats-json && webpack-bundle-analyzer dist/gps/stats.json",
    "cy:run": "cypress run",
    "cy:open": "cypress open",
    "e2e": "concurrently \"npm run serve:local\" \"npm run cy:run\"",
    "e2e:open": "concurrently \"npm run serve:local\" \"npm run cy:open\"",
    "e2e:ci:dev": "start-server-and-test serve:dev http://localhost:4200 cy:run",
    "e2e:ci:preprod": "start-server-and-test serve:preprod http://localhost:4200 cy:run",
    "compodoc:build": "compodoc -p tsconfig.doc.json",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s",
    "compodoc:serve": "compodoc -s",
    "release": "standard-version",
    "release:minor": "standard-version --release-as minor && bash release.sh",
    "release:patch": "standard-version --release-as patch && bash release.sh",
    "release:major": "standard-version --release-as major && bash release.sh",
    "commit": "npx cz"
  },
  "config": {
    "commitizen": {
      "path": "@commitlint/cz-commitlint"
    }
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular-material-components/file-input": "^7.0.0",
    "@angular/animations": "^18.1.3",
    "@angular/cdk": "16.2",
    "@angular/common": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/forms": "^18.1.3",
    "@angular/google-maps": "^15.2.9",
    "@angular/material": "^16.2.0",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@angular/service-worker": "^18.1.3",
    "@angular/youtube-player": "^14.1.0",
    "@ngneat/until-destroy": "^9.2.2",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/router-store": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@sentry/angular": "^6.17.4",
    "@sentry/tracing": "^6.17.4",
    "angular-oauth2-oidc": "^13.0.1",
    "dayjs": "^1.10.7",
    "gsap": "^3.6.0",
    "gzipper": "~6.0.0",
    "hammerjs": "2.0.8",
    "is-ci": "^3.0.0",
    "js-sha1": "^0.6.0",
    "marked": "^4.0.17",
    "ngrx-store-freeze": "^0.2.4",
    "npm": "^10.9.2",
    "rxjs": "^7.4.0",
    "transformation-matrix": "^2.6.0",
    "tslib": "2.2",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^14.0.2",
    "@angular-eslint/eslint-plugin": "^14.0.2",
    "@angular-eslint/eslint-plugin-template": "^14.0.2",
    "@angular-eslint/schematics": "^14.0.2",
    "@angular-eslint/template-parser": "^14.0.2",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@angular/language-service": "^18.1.3",
    "@commitlint/cli": "^17.0.3",
    "@commitlint/config-conventional": "^17.0.3",
    "@commitlint/cz-commitlint": "^17.0.3",
    "@compodoc/compodoc": "^1.1.19",
    "@cypress/schematic": "^1.6.0",
    "@hirez_io/observer-spy": "^2.2.0",
    "@ngrx/eslint-plugin": "^14.3.2",
    "@ngrx/schematics": "^14.3.2",
    "@types/hammerjs": "^2.0.41",
    "@types/jasmine": "^3.6.3",
    "@types/marked": "^4.0.3",
    "@types/node": "^22.1.0",
    "@typescript-eslint/eslint-plugin": "^5.51.0",
    "@typescript-eslint/parser": "^5.51.0",
    "commitizen": "^4.2.5",
    "concurrently": "^7.0.0",
    "cypress": "latest",
    "eslint": "^8.18.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-airbnb-typescript": "^16.0.0",
    "eslint-config-prettier": "^8.1.0",
    "eslint-plugin-import": "^2.23.4",
    "eslint-plugin-jsdoc": "^37.0.3",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-rxjs": "^5.0.0",
    "jasmine-core": "^3.7.1",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.3.10",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "2.1",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "standard-version": "^9.5.0",
    "start-server-and-test": "^2.0.5",
    "stylelint": "^14.1.0",
    "stylelint-config-prettier": "^9.0.3",
    "stylelint-config-recommended-scss": "^5.0.1",
    "stylelint-config-standard": "^24.0.0",
    "stylelint-scss": "^4.0.0",
    "ts-node": "^9.1.1",
    "typescript": "5.5.4",
    "webpack-bundle-analyzer": "^4.5.0"
  },
  "optionalDependencies": {
    "husky": "^7.0.1",
    "lint-staged": "^12.0.2",
    "prettier": "^2.5.1"
  }
}
